import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class ThankYouPage extends React.Component {
  render() {
    const siteTitle = 'Thank you!'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} />
        <div className="page-heading text-center text-white">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h1 className="page-content-heading pt-3">Thank you!</h1>
                <p className="pt-3">
                  We have received your message and will get back to you
                  shortly.
                </p>
                <Link
                  class="btn btn-primary pink-btn btn-arrow text-white"
                  role="button"
                  to="/"
                  title="Back btn"
                >
                  Take Me Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ThankYouPage

export const thankYouPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
